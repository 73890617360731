import { uuid } from "uuidv4";
import { Target, getObject, putObject } from "./s3";

class UploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this.loader.file.then(file => {
        const fileUUID = 'public/' + uuid();
        putObject(Target.PHOTO, fileUUID, file?.name, file)
          .then(() => {
            return getObject(Target.PHOTO, fileUUID, file?.name);
          })
          .then(url => {
            const urlObject = new URL(url);
            const finalUrl = `${urlObject.origin}${urlObject.pathname}`
            resolve({
              default: finalUrl
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  }
}

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    // Configure the URL to the upload script in your back-end here!
    return new UploadAdapter(loader);
  };
}

const editorConfiguration = {
  toolbar: [
    // "undo",
    // "redo",
    // "heading",
    // "|",
    "fontSize",
    "fontBackgroundColor",
    "fontColor",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "alignment",
    // "|",
    // "bulletedList",
    // "numberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "imageUpload"
  ],
  language: "en",
  indentBlock: {
    offset: 1,
    unit: "em"
  },
  image: {
    toolbar: ["imageStyle:inline", "imageStyle:block", "imageStyle:side"]
  },
  extraPlugins: [CustomUploadAdapterPlugin]
};

export default editorConfiguration;
