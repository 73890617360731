import { useQuery } from "@apollo/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, makeStyles } from "@material-ui/core";
import Editor from "ckeditor5-custom-build";
import { Dialog, translate } from "components";
import useEmblaCarousel from "embla-carousel-react";
import { GET_DASHBOARD_NOTICES } from "queries/notification";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CCButton, CCIconButton, CCTypography } from "styles/components";
import { ArrowLeftIcon, ArrowRightIcon } from "theme/icons";
import { NOTICE_TYPES } from "types/notice";
import { sortArrayByKey } from "utils/array";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper": {
      width: 700
    }
  },
  content: {
    padding: 20,
    height: 700
  },
  noticeItem: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.hover
    }
  },
  noticePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 4
  },
  noData: {
    height: 660,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  actions: {
    flex: 1,
    marginLeft: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  btnActionClose: {
    position: "absolute",
    right: 0,
    top: 0
  }
}));

const LIMIT_PAGE = 5;

const DialogPopupPreview = ({ open, onClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const { data, loading } = useQuery(GET_DASHBOARD_NOTICES, {
    variables: {
      limit: LIMIT_PAGE,
      type: NOTICE_TYPES.POPUP,
      isPublished: true
    }
  });
  const notices = sortArrayByKey({
    array: data?.dashboardNotices?.items || [],
    sortKey: "order",
    isSortKeyString: false
  });

  const totalPage = data?.dashboardNotices?.items?.length ?? 0;

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    index => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onInit = useCallback(emblaApi => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(emblaApi => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={translate(intl, "noticeTab.popup")}
        contents={
          <Box className={classes.content}>
            {!loading && notices.length > 0 && (
              <Box className="embla__viewport" ref={emblaRef}>
                <Box className="embla__container">
                  {notices.map((itm, idx) => (
                    <Box className="embla__slide" key={idx}>
                      <Box>
                        <CCTypography
                          variant="h3"
                          align="center"
                          style={{ wordBreak: "break-all" }}
                        >
                          {itm.title}
                        </CCTypography>
                        <Box
                          height={610}
                          overflow="auto"
                          className="ck-content-readonly"
                        >
                          <CKEditor
                            editor={Editor}
                            data={itm.content}
                            disabled={true}
                            config={{ toolbar: [], isReadOnly: true }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {!loading && notices.length === 0 && <NoData />}
            {loading && <Box className={classes.noData}></Box>}
          </Box>
        }
        classes={{ root: classes.root }}
        contentsPadding={false}
        maxWidth={false}
        fullWidth
        startActions={
          <Box className={classes.actions}>
            <Box
              aria-label="notice-pagination"
              className={classes.noticePagination}
            >
              <Box display="flex" alignItems="center" gridGap="2px">
                <PrevButton
                  onClick={scrollPrev}
                  disabled={selectedIndex === 0}
                />
                <Box display="flex">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      onClick={() => scrollTo(index)}
                      className={"embla__dot".concat(
                        index === selectedIndex ? " embla__dot--selected" : ""
                      )}
                    />
                  ))}
                </Box>
                <NextButton
                  onClick={scrollNext}
                  disabled={selectedIndex === totalPage - 1}
                />
              </Box>
            </Box>

            <CCButton
              color="sub"
              className={classes.btnActionClose}
              onClick={onClose}
            >
              {translate(intl, "button.close")}
            </CCButton>
          </Box>
        }
      />
    </>
  );
};

export default DialogPopupPreview;

const PrevButton = ({ disabled, ...props }) => {
  return (
    <CCIconButton {...props} style={{ margin: 0 }} disabled={disabled}>
      <ArrowLeftIcon
        style={{
          color: "#455A64",
          opacity: disabled ? 0.3 : 1
        }}
      />
    </CCIconButton>
  );
};

const NextButton = ({ disabled, ...props }) => {
  return (
    <CCIconButton {...props} style={{ margin: 0 }} disabled={disabled}>
      <ArrowRightIcon
        style={{
          color: "#455A64",
          opacity: disabled ? 0.3 : 1
        }}
      />
    </CCIconButton>
  );
};

const DotButton = props => {
  return <button type="button" {...props}></button>;
};

const NoData = props => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Box className={classes.noData}>
      <img src="/images/noData/Notoce_none.png" alt="noData" />
      <CCTypography
        variant="body1"
        display="block"
        style={{ color: "#b0bec5", fontWeight: "bold" }}
      >
        {translate(intl, "noPopup")}
      </CCTypography>
    </Box>
  );
};
