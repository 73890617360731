export const colors = {
  white: "#ffffff",
  pineGreen: "#00796b",
  supernova: "#ffcc00",
  razzmatazz: "#f50057",
  orient: "#015384",
  carnation: "#F15D5D",
  gray200: "#E9E9E9",
  gray300: "#AFAFAF",
  teal400: "#03AEAD",
  blue400: "#03A1DD",
  blue500: "#016B9F",
  primary: "#00897b",
  secondary: "#ff8585",
  red200: "#FAECEC",
  antiFlashWhite: "#e8f7f5",
  crayola: "#42382F",
  jackoBean: "#2f3942"
};

export const zeplinPalette = {
  // zeplin palette
  basic200: "#263238",
  basic300: "#5f6b75",
  basic400: "#8ca0aa",
  basic500: "#c0d3dc",
  basic600: "#f3f8fc",

  gray100: "#fafafa",
  gray300: "#eceff1",
  gray400: "#dae5ed",
  gray500: "#cfd8dc",
  gray600: "#a0a9a8",
  gray800: "#2f3942",

  line400: "#b0bec5",
  line500: "#d3dce3",

  tea: "",
  teal200: "#e8f7f5",
  teal500: "#00bfa5",
  teal700: "#00897b",
  teal800: "#007767",

  red: "#be2a20",
  red600: "#d2181a"
};
