import MuiAutocomplete from "./MuiAutocomplete";
import MuiButton from "./MuiButton";
import MuiInput from "./MuiInput";
import MuiInputLabel from "./MuiInputLabel";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiTextField from "./MuiTextField";

export default {
  MuiAutocomplete,
  MuiInput,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiTextField,
  MuiButton
};
