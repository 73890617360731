import { colors } from "@material-ui/core";

const palette = {
  primary: {
    main: "#00897b",
    dark: "#005F56",
    sub: "#00BFA5"
  },
  secondary: {
    main: "#0277BD",
    dark: "#015384"
  },
  error: {
    main: "#e1315a",
    dark: "#AB003C"
  },
  select: {
    dark: "#2c89b2",
    main: "#40c4ff",
    light: "#66cfff",
    hover: "#27799e"
  },
  normal: {
    dark: "#F4F6F8",
    main: "#fff",
    hover: "#ababab"
  },
  sub: {
    dark: "rgba(0, 0, 0, 0.78)",
    darkHex: "#383838",
    main: "rgba(0, 0, 0, 0.54)",
    mainHex: "#878787"
  },
  oe: "#FFAB00",
  font: {
    hex: "#212121",
    rgba: "rgba(0, 0, 0, 0.87)"
  },
  disabled: {
    hex: "#ababab",
    rgba: "rgba(0, 0, 0, 0.38)"
  },
  inactive: {
    hex: "#eceff1",
    rgba: "rgba(236, 239, 241, 1)"
  },
  result: {
    main: "#CFD8DC",
    blank: "#D5DDE0"
  },
  text: {
    primary: colors.blueGrey[900], //#263238
    secondary: colors.blueGrey[700], //#455a64
    link: colors.blue[600], //#1e88e5
    label: colors.blueGrey[400] //#78909c
  },
  input: {
    main: "rgba(0, 0, 0, 0.38)",
    hover: "rgba(0, 0, 0, 0.87)"
  },
  background: {
    default: "#eceff1",
    paper: "#fff",
    dialogHeader: "#364146"
  },
  hover: "rgba(69, 90, 100, 0.06)",
  icon: "#01579b",
  divider: colors.blueGrey["A100"], //#cfd8dc
  border: {
    main: colors.blueGrey["A100"], //#cfd8dc
    dark: colors.blueGrey["A200"], //#b0bec5
    active: "#2196f3",
    guide: "#76B5DB"
  },
  focus: "#b6e8ff",
  focusBorder: "#0091ff"
};
export default palette;
