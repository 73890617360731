import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Button, Divider, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CCSearchTextField, CVTable, TimerWithRefetch } from "components";
import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";
import { GET_LAB_APPLICATION_REQ_LIST } from "queries/application";
import {
  GET_STAFF_BASIC_INFO_LIST,
  MANAGER_RESET_STAFF_PASSWORD
} from "queries/setting";
import { useEffect, useRef, useState } from "react";
import {
  CCButton,
  CCDialogTitle,
  CCTextField,
  CCTypography
} from "styles/components";
import { RefreshIcon, SearchIcon } from "styles/icons";

import { Pagination } from "@material-ui/lab";

const LIMIT = 12;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default
  },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  item__table: { height: "calc(100% - 205px)" },
  table: {
    height: "100%",
    textAlign: "center"
  },
  table__body: {
    // backgroundColor: "rgba(207, 216, 220, 0.87)",
    // "& > div:nth-child(old)": {
    //   background: theme.palette.common.white
    // }
    minHeight: 36
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },

  is_recommended: {
    color: "#ffab00"
  },
  divider: {
    height: 2
  },
  selection: {
    width: 350
  },
  selectionText: {
    color: "red"
  },
  searchtext__field: { width: 320 },
  ml8: {
    marginLeft: 8
  },
  refresh__icon: {
    paddingTop: 4,
    position: "absolute",
    right: 30,
    cursor: "pointer"
  },
  container__filter: {
    width: "100%",
    padding: "0px 4px 12px 2px",
    margin: "0px",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  background_color_white: {
    backgroundColor: theme.palette.common.white
  }
}));

const filter = {
  status: "1"
};

const PasswordManagement = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [managerResetStaffPassword] = useMutation(
    MANAGER_RESET_STAFF_PASSWORD,
    {
      onCompleted: data => {
        console.log("changePassword", data);

        openToast("Password has been reset");
      }
    }
  );

  const inputRef = useRef();

  const openToast = (message = "") => {
    enqueueToast(enqueueSnackbar, message);
  };

  const heads = [
    {
      key: "staffId",
      label: "User Id",
      width: "10%",
      padding: true,
      className: classes.table__cell
    },
    {
      key: "staffName",
      width: "40%",
      label: "Name",
      className: classes.table__cell
    },
    {
      key: "",
      width: "40%",
      label: "",
      className: classes.table__cell
    },
    {
      key: "id",
      label: "",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCButton
            variant={"contained"}
            size={"small"}
            color={"normal"}
            onClick={() =>
              managerResetStaffPassword({
                variables: {
                  id: cellData
                }
              })
            }
          >
            {"Reset Password"}
          </CCButton>
        );
      }
    }
  ];
  const [staffLimit, setStaffLimit] = useState(LIMIT);
  const { data: approvedLabList } = useQuery(GET_LAB_APPLICATION_REQ_LIST, {
    variables: {
      input: {
        status: "1",
        usePagination: false,
        limit: 99999
      }
    }
  });
  const [getStaff, { data: staffList }] = useLazyQuery(
    GET_STAFF_BASIC_INFO_LIST
  );
  const [selectedLab, setSelectedLab] = useState();
  const [keyword, setKeyword] = useState();

  const totalPages = Math.ceil(
    staffList?.managerGetStaffList?.total / staffLimit
  );
  const [page, setPage] = useState(1);
  const handleOnChangePagination = (event, value) => {
    setPage(value);
  };

  const handleOnSearch = () => {
    console.log("inputRef.current.value,", inputRef.current.value);
    getStaff({
      variables: {
        query: {
          labId: selectedLab,
          searchText: inputRef.current.value,
          limit: staffLimit
        }
      }
    });
  };

  useEffect(() => {
    if (!selectedLab) {
      return;
    }
    getStaff({
      variables: {
        query: {
          labId: selectedLab,
          limit: staffLimit,
          page: page
        }
      }
    });
  }, [selectedLab, page]);

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle className={classes.background_color_white}>
            Password Reset
          </CCDialogTitle>
          <Grid
            className={classes.container__filter}
            container
            justify="flex-start"
            direction="row"
          >
            <CCTextField
              value={selectedLab}
              label={"Dental Lab"}
              select
              variant="outlined"
              margin="dense"
              className={classes.selection}
              onChange={e => setSelectedLab(e.target.value)}
            >
              {approvedLabList &&
                (approvedLabList?.labApplicationRequestList?.items || []).map(
                  item => (
                    <MenuItem key={item.id} value={item.labId}>
                      {item.labName}
                    </MenuItem>
                  )
                )}
            </CCTextField>
            <CCTypography className={classes.selectionText}>
              * Please select a lab to reset passwords.
            </CCTypography>
          </Grid>
          <Grid
            container
            className={classes.background_color_white}
            justify="space-between"
          >
            <Grid container justify="flex-start" alignItems="center" xs={9}>
              <CCSearchTextField
                // placeholder={"Search"}
                disabled={!selectedLab}
                label="User ID, Name"
                className={classes.searchtext__field}
                variant="outlined"
                margin="dense"
                inputRef={inputRef}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    inputRef.current.value = e.target.value;
                    handleOnSearch();
                  }
                }}
                onChange={value => {
                  inputRef.current.value = value;
                }}
                onClear={() => {
                  inputRef.current.value = "";
                  handleOnSearch();
                }}
              />

              {/* <CCTextField
                className={classes.searchtext__field}
                variant="outlined"
                margin="dense"
                label="UserID, Name"
                InputProps={{ classes: { root: classes.input } }}
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                disabled={!selectedLab}
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    getStaff({
                      variables: {
                        query: {
                          labId: selectedLab,
                          searchText: keyword,
                          limit: staffLimit
                        }
                      }
                    })
                  }
                }}
              /> */}
              <CCButton
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon />}
                className={classes.ml8}
                disabled={!selectedLab}
                onClick={() => {
                  handleOnSearch();
                  // getStaff({
                  //   variables: {
                  //     query: {
                  //       labId: selectedLab,
                  //       limit: staffLimit
                  //     }
                  //   }
                  // });
                }}
              >
                Search
              </CCButton>
              <Button
                className={classes.refresh__icon}
                disabled={!selectedLab}
                onClick={() => {
                  inputRef.current.value = "";
                  getStaff({
                    variables: {
                      query: {
                        labId: selectedLab,
                        searchText: keyword,
                        limit: staffLimit
                      }
                    }
                  });
                }}
              >
                <RefreshIcon fontSize="large" color="blue" />
              </Button>
            </Grid>
          </Grid>

          <Divider className={classes.divider}></Divider>
          <Grid className={classes.background_color_white}>
            <Grid item>
              <CVTable
                heads={heads}
                contents={staffList?.managerGetStaffList?.items}
                className={classes.table}
              />
            </Grid>
            <Divider className={classes.divider}></Divider>
            <Grid container item justifyContent="center" direction="column">
              {staffList && (
                <Pagination
                  count={totalPages}
                  page={page}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={handleOnChangePagination}
                  showFirstButton
                  showLastButton
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PasswordManagement;
