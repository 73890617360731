import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InfoIcon } from "styles/icons";
import { colors, zeplinPalette } from "theme/colors";

const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${zeplinPalette.teal500}`,
    borderRadius: 12
  },
  error__container: {
    border: `1px solid ${zeplinPalette.red}`
  },
  title: {
    fontWeight: "bold",
    color: colors.orient
  },
  sub__title: {
    fontStyle: "italic",
    color: colors.orient
  },
  paperWidthSm: {
    minWidth: 300,
    borderRadius: 12
  },
  dialog__content: {
    display: "flex",
    alignItems: "center"
  },
  info__icon: {
    fontSize: 48,
    marginRight: 16,
    color: colors.orient
  },
  error: {
    color: zeplinPalette.red
  },
  success: {
    color: zeplinPalette.teal500
  },
  warning: {
    color: zeplinPalette.teal500
  },
  title_warning: {
    color: zeplinPalette.gray800
  },
  title_error: {
    color: zeplinPalette.gray800
  },
  title_success: {
    color: zeplinPalette.gray800
  },
  action__button: {
    textTransform: "none",
    padding: "8px 16px",
    cursor: "pointer",
    backgroundColor: zeplinPalette.gray400,
    borderRadius: 5,
    minWidth: 60,
    display: "flex",
    justifyContent: "center"
  }
}));

const DialogConfirmation = props => {
  const {
    onClose,
    title,
    subTitle,
    okTitle,
    cancelTitle,
    onConfirm,
    type,
    onCancel,
    subTitle2,
    ...rest
  } = props;

  const classes = useStyles();

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  return (
    <Dialog
      {...rest}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <div
        className={clsx(classes.root, {
          [classes.error__container]: type === "error"
        })}
      >
        <DialogContent className={classes.dialog__content}>
          <InfoIcon
            className={clsx(classes.info__icon, {
              [classes.error]: type === "error",
              [classes.success]: type === "success",
              [classes.warning]: type === "warning"
            })}
          />
          <div>
            <div
              className={clsx(classes.title, {
                [classes.title_error]: type === "error",
                [classes.title_success]: type === "success",
                [classes.title_warning]: type === "warning"
              })}
            >
              {title}
            </div>
            <div
              className={clsx(classes.sub__title, {
                [classes.error]: type === "error",
                [classes.success]: type === "success",
                [classes.warning]: type === "warning"
              })}
            >
              {subTitle}
            </div>
            {!!subTitle2 && (
              <div
                className={clsx(classes.sub__title, {
                  [classes.error]: type === "error",
                  [classes.success]: type === "success",
                  [classes.warning]: type === "warning"
                })}
              >
                {subTitle2}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {cancelTitle && (
            <div onClick={handleCancel} className={classes.action__button}>
              {cancelTitle}
            </div>
          )}
          {onConfirm ? (
            <div onClick={handleConfirm} className={classes.action__button}>
              {okTitle}
            </div>
          ) : null}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DialogConfirmation;
