import shape from "../default/shape";

export default {
  root: {
    borderRadius: shape.borderRadius
  },
  contained: {
    backgroundColor: "white",
    boxShadow: "none"
  },
  containedPrimary: {
    color: "white"
  }
};
